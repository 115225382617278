import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CircularProgress } from '@mui/material';
import RenderAsset from '@shared/ui/display/table-renderers/RenderAsset';
import RenderOrderSize from '@shared/ui/display/table-renderers/RenderOrderSize';
import RenderEntryPrice from '@shared/ui/display/table-renderers/RenderEntryPrice';
import RenderPnl from '@shared/ui/display/table-renderers/RenderPnl';
import RenderAssetDuration from '@shared/ui/display/table-renderers/RenderAssetDuration';
import tableRendererWrapper from '@shared/ui/display/Table/tableRendererWrapper';
import { useGetPortfolioCurrentPositionsQuery } from '@store/slices/portfolio/api';
import { IsDefined } from '@shared/lib/Helpers';
import Table from '@shared/ui/display/Table';
import { ContainerColumn, SurfaceColumn } from '../../../styled';
import PnlHeaderCell from '../PnlHeaderCell';
const MinHeight = 486;
const Container = styled(SurfaceColumn)(props => ({
    boxSizing: 'border-box',
    height: 'auto',
    padding: props.theme.spacing_sizes.l,
    // TODO: move shadow to theme
    boxShadow: '0px 1px 3px 0px #E6E8EB',
}));
const LoaderContainer = styled(ContainerColumn)(() => ({
    flex: 1,
    minHeight: MinHeight,
    alignItems: 'center',
    justifyContent: 'center',
}));
const getColumnsDefinition = (t) => ([
    {
        header: t('table.current_positions.asset'),
        accessorKey: 'asset',
        enableSorting: false,
        meta: {
            flex: 1,
        },
        cell: tableRendererWrapper(RenderAsset),
    },
    {
        header: t('table.current_positions.order_size'),
        accessorKey: 'order_size',
        enableSorting: false,
        meta: {
            flex: 1,
        },
        cell: tableRendererWrapper(RenderOrderSize),
    },
    {
        header: t('table.current_positions.entry_price'),
        accessorKey: 'entry_price',
        enableSorting: false,
        meta: {
            flex: 1,
        },
        cell: tableRendererWrapper(RenderEntryPrice),
    },
    {
        header: PnlHeaderCell,
        accessorKey: 'pnl',
        enableSorting: true,
        meta: {
            flex: 1,
            align: 'right',
            headerAlign: 'right',
        },
        cell: tableRendererWrapper(RenderPnl),
    },
    {
        header: t('table.current_positions.duration'),
        accessorKey: 'opened',
        enableSorting: false,
        meta: {
            flex: 1,
        },
        cell: tableRendererWrapper(RenderAssetDuration),
    },
]);
const GetCurrentPositionsQueryTake = 10;
const CurrentPositionsTable = ({ className = undefined, portfolioId, }) => {
    const { t } = useTranslation();
    const columnsDefinition = useMemo(() => getColumnsDefinition(t), [t]);
    const [sortingState, setSortingState] = useState([]);
    const [paginationState, setPaginationState] = useState({
        pageIndex: 0,
        pageSize: GetCurrentPositionsQueryTake,
    });
    const skip = useMemo(() => paginationState.pageIndex * paginationState.pageSize, [paginationState]);
    const { data, isLoading, isFetching, isError, } = useGetPortfolioCurrentPositionsQuery({
        portfolioId,
        skip,
        take: GetCurrentPositionsQueryTake,
        sortByPnl: sortingState.length && sortingState[0].desc ? 'desc' : 'asc',
    });
    const isChunkEmpty = useMemo(() => !IsDefined(data) || !data.data.length, [data]);
    return (_jsxs(Container, { className: className, style: Object.assign({}, (isChunkEmpty && ({
            minHeight: MinHeight,
        }))), children: [isLoading && (_jsx(LoaderContainer, { children: _jsx(CircularProgress, {}) })), !isFetching &&
                isError &&
                _jsx("p", { children: "error" }), !isError &&
                IsDefined(data) && (_jsx(Table, { data: data.data, rowsCount: data.count, columnDef: columnsDefinition, paginationState: paginationState, onPaginationChange: setPaginationState, sortingState: sortingState, onSortingChange: setSortingState, isLoading: isFetching }))] }));
};
export default CurrentPositionsTable;
